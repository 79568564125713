* {
  background-color: #282c34;
  color: #ffffff;

}
.grid_pattern_background {
  // background-color: #ffffff;
  // opacity: 0.8;
  background-image:  linear-gradient(#396183 2px, transparent 2px), linear-gradient(90deg, #396183 2px, transparent 2px), linear-gradient(#396183 1px, transparent 1px), linear-gradient(90deg, #396183 1px, #282c34 1px);
  background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
  padding: 1em 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #282c34;
  color: #ffffff;
  padding: 16px 32px;
}

nav a {
  text-decoration: none;
  margin-right: 16px;
  font-weight: bold;
  color: #ffe8c1;
}

nav a.active {
  text-decoration: none;
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.head-container {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
}

.progress-container {
  // background: red;
  padding: 0 10%;
  height: 3em;
  // background: red;
  .progress {
    background-color: #4b4b4b;
    // --bs-progress-bar-bg: #ffe8c1;
    // --bs-progress-bar-bg: #ffe2b0;
    // --bs-progress-bar-bg: #934545;
    // --bs-progress-bar-bg: #746cd1;
    // --bs-progress-bar-bg: #d16c6c;
    --bs-progress-bar-bg: #458e93;
  }
  .progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(255,255,255,0.25) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.25) 50%,rgba(255,255,255,.25) 75%,transparent 75%,transparent);
  }
  .progress-bar-animated {
    animation: 3s linear infinite progress-bar-stripes;
  }
}
.game-title {
  font-size: 2em;
  font-weight: bold;
}

.addem-numbers-page-container {
  background-color: #ff0;
  min-height: 100vh;
}

.page-content {
  // background-color: #0ff;
  position: relative;
  // left: 10%;
  width: 100%;
  padding-bottom: 5%;
  padding-top: 5%;
}
.sub-heading {
  font-weight: bold;
  font-size: 1.2em;
}
.simple-paragraph {
  margin-bottom: 40px;
}

.wrapper {
  background-color: #ffffff;
  min-width: fit-content;
  max-width: 50%;
  border-radius: 1.5em;
  padding: 2.5em;
  -webkit-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  // display: inline-flex;
}

.product-img {
  height: 10em;
  width: 10em;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 1.5em;
  -webkit-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
}

.product-thumbnail {
  height: 10em;
  width: 10em;
}

.app-link-button {
  background-color: black;
  padding: 1em;
  margin: 1em 0;
  border-radius: 1em;
  max-width: fit-content;
  min-width: 10em;
  cursor: pointer;
  a {
    text-decoration: none;
    color: #ffffff
  }
}
.web-games-page-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #282c34;
  color: #ffffff;
  .page-content {
    text-align: center;
    // background-color: red;
  }
}
.unizone-game {
  width: 250px;
  height: 400px;
}
.account-page-container {
  .page-content {
    text-align: center;
  }
}
.gameScreenContainer {
}

.web-games-page-container {
  // background-color: red;
}

.landscape-webgame-canvas {
  width: 640px;
  height: 360px;
}

.shoot-or-die-page-container {
  text-align: center;
  .page-content {
    h1 {
      text-align: center;
    }
    padding: 5% 10%;
    text-align: left;
  }
}
.gameScreenshotContainer {
  text-align: center;
  margin: 30px 0;
  .gameScreenshotLandscape {
    width: 60%;
  }
}
.pointer-dot {
  background-color: #ff0;
  mix-blend-mode: exclusion;
  z-index: 1000;
}